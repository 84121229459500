import { KycProviders } from '#reducers/kyc/types';

export interface IKycConfig {
  type: KycProviders,
}

export default {
  type: KycProviders.Manual,
  primetrust: {
    theme: {
      background: "#F5F5F5",
      foreground: "#000000",
      primary: "#117749",
      success: "#117749",
      error: "#EB4F3B",
    }
  }
} as IKycConfig

export interface IKYCConstant {
  uploadImages: string[];
}

export const KYCConstant: IKYCConstant = {
  uploadImages: ['.jpeg', '.jpg', '.png', '.pdf']
};
