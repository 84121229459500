import vaultIcon from '#images/icons/Vault_logo.svg';
import vaultIconActive from '#images/icons/Vault_logo_active.svg';
import iconCryptoPay from '#images/icons/icon-crypto-pay.svg';
import iconCryptoPayHover from '#images/icons/icon-crypto-pay-hover.svg';

import { AnaliticsIds } from './analytics';
import { useCryptoPay, useLending } from './config-env';
import { Paths } from '#/config/templates/nova/nova';

export type Nav = {
  title: string,
  translation: string,
  link?: string,
  customLink?: string,
  icon?: string,
  customIcon?: string,
  customIconHover?: string,
  use?: boolean,
  isPulsing?: boolean,
  handler?: Function,
  id?: string,
  isAuth?: boolean,
  cryptoPay?: boolean,
};

export const sideDesktopNavigation: Array<Nav> = [
  {
    title: 'Dashboard',
    translation: 'NAV.DASHBOARD',
    link: Paths.Dashboard,
    icon: 'fas fa-th-large',
    isAuth: true,
    id: AnaliticsIds.linkHome,
  },
  {
    title: 'Log In',
    translation: 'NOVA_AUTH_REQUIRED.LOG_IN',
    link: Paths.Login,
    icon: 'fas fa-arrow-right',
    isAuth: false,
    id: AnaliticsIds.accLogin,
  },
  {
    title: 'Exchange',
    translation: 'NAV.TRADES',
    link: Paths.Exchange,
    icon: 'fas fa-exchange',
    id: AnaliticsIds.linkTrade,
  },
  {
    title: 'Markets',
    translation: 'NAV.MARKETS',
    link: Paths.Markets,
    icon: 'fas fa-chart-bar',
    id: AnaliticsIds.linkMarket,
  },
  {
    title: 'Quick Trade',
    translation: 'NAV.QUICK_TRADE',
    link: Paths.QuickBuySell,
    icon: 'fa fa-bolt',
    isAuth: true,
    id: AnaliticsIds.linkQuickBuySell,
  },
  {
    title: 'Wallets',
    translation: 'NAV.WALLETS',
    link: Paths.Wallets,
    icon: 'fa fa-wallet',
    isAuth: true,
    id: AnaliticsIds.linkWallet,
  },
  {
    title: 'Vaults',
    translation: 'NAV.VAULTS',
    link: Paths.Vaults,
    customIcon: vaultIcon,
    customIconHover: vaultIconActive,
    use: useLending,
    id: AnaliticsIds.linkVaults,
    isAuth: true,
    isPulsing: true,
  },
  {
    title: 'Crypto Pay',
    translation: '',
    link: Paths.CryptoPay,
    customIcon: iconCryptoPay,
    customIconHover: iconCryptoPayHover,
    use: useCryptoPay,
    isAuth: true,
    id: AnaliticsIds.linkCryptoPay,
  },
  {
    title: 'Settings',
    translation: 'NAV.SETTINGS',
    link: Paths.Settings,
    icon: 'fa fa-wrench',
    isAuth: true,
    id: AnaliticsIds.linkSettings,
  },
  // @ts-ignore
];

export const sideDesktopBottomNavigation: Array<Nav> = [];

export const sideMobileNavigation: Array<Nav> = [
  {
    title: 'Dashboard',
    translation: 'NAV.DASHBOARD',
    link: Paths.Dashboard,
    icon: 'fas fa-th-large',
    isAuth: true,
    id: AnaliticsIds.linkHome,
  },
  {
    title: 'Log In',
    translation: 'NOVA_AUTH_REQUIRED.LOG_IN',
    link: Paths.Login,
    icon: 'fas fa-arrow-right',
    isAuth: false,
    id: AnaliticsIds.accLogin,
  },
  {
    title: 'Exchange',
    translation: 'NAV.TRADES',
    link: Paths.Exchange,
    icon: 'fas fa-exchange',
    id: AnaliticsIds.linkTrade,
  },
  {
    title: 'Markets',
    translation: 'NAV.MARKETS',
    link: Paths.Markets,
    icon: 'fas fa-chart-bar',
    id: AnaliticsIds.linkMarket,
  },
  {
    title: 'Quick Trade',
    translation: 'NAV.QUICK_TRADE',
    link: Paths.QuickBuySell,
    icon: 'fas fa-bolt',
    isAuth: true,
    id: AnaliticsIds.linkQuickBuySell,
  },
  {
    title: 'Wallets',
    translation: 'NAV.WALLETS',
    link: Paths.Wallets,
    icon: 'fa fa-wallet',
    isAuth: true,
    id: AnaliticsIds.linkWallet,
  },
  {
    title: 'Vaults',
    translation: 'NAV.VAULTS',
    link: Paths.Vaults,
    customIcon: vaultIcon,
    use: useLending,
    isAuth: true,
    id: AnaliticsIds.linkVaults,
  },
  {
    title: 'Crypto Pay',
    translation: '',
    link: Paths.CryptoPay,
    customIcon: iconCryptoPayHover,
    use: useCryptoPay,
    isAuth: true,
    id: AnaliticsIds.linkCryptoPay,
  },
  {
    title: 'Settings',
    translation: 'NAV.SETTINGS',
    link: Paths.Settings,
    icon: 'fa fa-wrench',
    isAuth: true,
    id: AnaliticsIds.linkSettings,
  },
];

export const headerNavigation: Array<Nav> = [
  // {
  //   title: 'FAQ',
  //   translation: 'NAV.FAQ',
  //   link: '/faq',
  //   icon: 'fas fa-question-circle',
  // },
  {
    title: 'Support',
    translation: 'NAV.SUPPORT',
    customLink: 'https://blockkoin.io/contact',
    icon: 'fad fa-comments',
  },
];
